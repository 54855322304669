import React from 'react';
import './header.scss';

export const Header = () => {

    return (
        <div className="header section">
            <div className="header-left">
                <img src="/header-left.png" alt="header-left"/>
            </div>
            <div className="header-middle">
                <div className="header-image"><img src="/algo-de-ritmo.png" alt="algo de ritmo"/> </div>
                <div className="header-explanation">
                    Combina <strong>bandas y géneros musicales</strong> y disfruta <br/>
                    de una <strong>playlist</strong> de artistas <br/>
                    <strong>independientes</strong> creada por mi Algoritmo.
                </div>
            </div>
            <div className="header-right">
                <img src="/header-right2.png" alt="placeholder-right"/>
            </div>
        </div>
    );
};
