export class ArtistModel {
    id: number;
    name: string;
    image: string;

    constructor(properties?: Partial<ArtistModel>) {
        Object.assign(this, properties);
    }
}

