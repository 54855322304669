import React from 'react';
import './App.scss';
import { Recommender } from './components/recommender/Recommender';
import { Video } from './components/video/Video';
import { About } from './components/about/About';
import { How } from './components/how/How';
import { Your } from './components/your/Your';
import { Header } from './components/header/header';

function App() {
    
    return (
        <div className="app">
            {/*<header className="app-header">*/}
            {/*    <div>Algo de Ritmo</div>*/}
            {/*</header>*/}
            <div className="app-content">
                <Header></Header>
                <Recommender></Recommender>
                
                <Video></Video>
                <About></About>
                <How></How>
                <Your></Your>
                
            </div>
        </div>
    );
}

export default App;
