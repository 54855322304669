import React from 'react';
import './actionButton.scss';

interface IProps {
    text: string;
    url: string;
}

export const ActionButton = ({text, url} : IProps) => {
    const handleClick = (): void => {
        window.open(url, '_blank');
    };
    
    return (
        <button className="action-button" type="button" onClick={handleClick}>{text}</button>
    )
};
