import { ArtistModel } from '../models/ArtistModel';

export async function SearchArtists(searchTerm: string): Promise<ArtistModel[]> {
    if (!searchTerm || searchTerm.trim().length === 0) {
        return [];
    }
    // Search artist
    return await SearchArtistBackend(searchTerm);
}

async function SearchArtistBackend(searchTerm: string): Promise<ArtistModel[]> {
    const url = `https://algoderitmo.herokuapp.com/search/${searchTerm}`;
    const response = await fetch(url).then(response => response.json());
    const result = response.map((x: [string, number, string]) => new ArtistModel({  id: x[1], name: x[0], image: x[2] }));
    return result;
}