import React from 'react';
import './Video.scss';

export const Video = () => {
    return (
        <div className="video section">
            <div className="video-title section-title">
                <img src="/el-algo-ritmo-video-header.png" alt="El Algoritmo video"/>
            </div>
            <div className="video-player">
                <iframe src="https://www.youtube.com/embed/uX66IK0Iufs"
                        title="Muyaio - El Algoritmo" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
        </div>
    )
};
