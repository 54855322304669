import { ArtistModel } from '../models/ArtistModel';

export async function GetArtistById(artist: number): Promise<ArtistModel> {
    const url = `https://algoderitmo.herokuapp.com/get-artist-data`;
    const body = {"artist": artist};
    const artistFound = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body) })
        .then(response => response.json());
    
    const artistModel = new ArtistModel({
        id: artistFound.artist_id,
        name: artistFound.artist_name,
        image: artistFound.image_url
    });
        
    return artistModel;
}

export async function GetRandomArtist(): Promise<ArtistModel> {
    const url = `https://algoderitmo.herokuapp.com/pick-artist/`;
    const artistFound = await fetch(url)
        .then(response => response.json());

    const artistModel = new ArtistModel({
        id: artistFound.artist_id,
        name: artistFound.artist_name,
        image: artistFound.image_url
    });
        
    return artistModel;
}