import React from 'react';
import { Search } from '../search/search';
import './right.scss';
import { ArtistModel } from '../../../models/ArtistModel';
import { Artist } from '../artist/artist';

interface IProps {
    artist: ArtistModel | null | undefined;
    onSearch: (search: ArtistModel | undefined) => void;
}

export const Right = ({artist, onSearch}: IProps) => {

    return (
        <div className="right">
            <div className="search"><Search onSearch={onSearch}></Search></div>
            <div className="artist"><Artist artist={artist}></Artist></div>
        </div>
    );
};
