import React  from 'react';
import './How.scss';
import { ActionButton } from '../buttons/actionButton';
export const How = () => {
    
    return (
        <div className="how section">
            <div className="how-row">
                <div className="how-left">
                    <img src="/como-left.png" alt="como"/>
                </div>
                <div className="how-middle">
                    <div className="how-text">
                        <div className="section-title"><span className="how-como">Como</span> funciona</div>
                        <span>
                            Los sistemas de recomendación funcionan de dos
                            maneras, se basan en lo que escuchan en común los
                            usuarios, o en el contenido que se está
                            recomendando, en este caso la música. La gran
                            mayoría de los algoritmos que usamos a diario (para
                            escuchar música, para comprar, para ver series) se
                            basan en la primera manera. El problema es que ésta
                            solo es capaz de recomendar cosas populares. Por
                            eso mi algoritmo se basa en la segunda forma,
                            analizando el audio de las canciones. Para ello
                            utilizo una gran red neuronal, una especie de GPT
                            de la música, que acabo de publicar en la mayor
                            conferencia mundial de música y tecnologia.
                        </span>
                        <div className="how-action">
                            <ActionButton text="Te cuento más" url="https://muyaio.com/2023/01/31/algoderitmo-recomendacion-musical-para-todos/"/>
                        </div>
                        
                    </div>
                </div>
                <div className="how-right">
                    <img className="how-right-top" src="/como-right-top.png" alt="como"/>
                    <img className="how-right-bottom" src="/como-right-bottom.png" alt="como"/>
                </div>
            </div>
        </div>
    )
};
