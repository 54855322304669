import React, { useEffect, useState } from 'react';
import './Recommender.scss';
import { Left } from './left/left';
import { Right } from './right/right';
import { Slider } from './slider/slider';
import { ArtistModel } from '../../models/ArtistModel';
import { GetRecommendationPlaylistId } from '../../data/algoritmo';
import { Recommendation } from './recommendation/recommendation';
import { useSearchParams } from "react-router-dom";
import { GetArtistById, GetRandomArtist } from '../../data/searchArtist';

export const Recommender = () => {
    const [artistLeft, setArtistLeft] = useState<ArtistModel | null | undefined>(undefined);
    const [artistRight, setArtistRight] = useState<ArtistModel | null | undefined>(undefined);
    const [position, setPosition] = useState(2);
    const [playlistId, setPlaylistId] = useState<string>('');
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        const left = parseInt(searchParams.get('artistLeft') || "0");
        // Muyaio 278
        const right = parseInt(searchParams.get('artistRight') || "278");
        let pos = parseInt(searchParams.get('position') || "2");

        if (position !== pos) {
            if (pos > 4) pos = 2;
            setPosition(pos);
        }

        if (left === 0) {
            GetRandomArtist().then(artist => {
                setArtistLeft(artist);
            });
        } else {
            if (!artistLeft || artistLeft?.id !== left) {
                GetArtistById(left).then(artist => {
                    setArtistLeft(artist);
                });
            }
        }

        if (!artistRight || artistRight?.id !== right) {
            GetArtistById(right).then(artist => {
                setArtistRight(artist);
            });
        }

    }, []);

    useEffect(() => {
        if (!artistLeft) {
            return;
        }
        if (!artistRight) {
            return;
        }
        setSearchParameters();
        GetRecommendationPlaylistId(artistLeft.id, artistRight.id, position + 1)
            .then(playlistId => {
                setPlaylistId(playlistId);
            });
    }, [position, artistLeft, artistRight]);

    function onChangeSlider(newPosition: number): void {
        setPosition(newPosition);
    }

    const onSearchArtistLeft = (artist: ArtistModel | undefined): void => {
        setArtistLeft(artist);
    };

    const onSearchArtistRight = (artist: ArtistModel | undefined): void => {
        setArtistRight(artist);
    };

    function setSearchParameters(): void {
        const left = artistLeft?.id;
        const right = artistRight?.id;

        setSearchParams({
            artistLeft: left?.toString() || '',
            artistRight: right?.toString() || '',
            position: position?.toString() || ''
        });
    }

    return (
        <>
            <div className="recommender section">
                <div className="recommender-content">
                    <div className="recommender-searcher">
                        <div className="recommender-left"><Left artist={artistLeft}
                                                                onSearch={onSearchArtistLeft}></Left></div>
                        <div className="recommender-right">
                            <Right artist={artistRight} onSearch={onSearchArtistRight}></Right>
                        </div>
                    </div>
                    <div className="recommender-slider">
                        <Slider position={position} onChange={onChangeSlider}></Slider>
                    </div>
                </div>
            </div>
            <Recommendation playlistId={playlistId}></Recommendation>
        </>
    );
};
    
    
