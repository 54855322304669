import React  from 'react';
import './muyaioSocial.scss';
import { SocialButton } from '../socialButton';

export const MuyaioSocial = () => {
    
    return (
        <div className="muyaio-social">
            <SocialButton type="instagram" url="https://www.instagram.com/muyaio/" />
            <SocialButton type="spotify" url="https://open.spotify.com/artist/1ze7Vr6ybqdkQ0jRT9HUhP" />
            <SocialButton type="web" url="https://muyaio.com/" />
        </div>
    )
};
