import React from 'react';
import './your.scss';
import { MuyaioSocial } from '../buttons/MuyaioSocial/muyaioSocial';
import { ActionButton } from '../buttons/actionButton';

export const Your = () => {

    return (
        <div className="your section">
            <div className="section-wrapper">
                <div className="section-content">
                    <div className="your-faltas-wrapper">
                        <div className="your-faltas-left">
                            
                        </div>
                        <div className="your-faltas">
                            <ActionButton text="¿Faltas tú?" url="https://forms.gle/NeT6kj3QGwJLN5qn7" /> 
                        </div>
                        <div className="your-faltas-right">
                            <img className="your-faltas-right-img" src="/faltas-right.png" alt="Faltas estrellas" />
                        </div>
                    </div>
                    <div className="your-description">
                        ¿Eres músico independiente y no están aqui tus canciones? <br/>
                        Completa el formulario y asi podré incluirte.<br/><br/>
                        ¿Quieres estar al tanto de novedades en la Web? <br/>
                        <a href="https://forms.gle/u33TPVf8zGgUdGCr9">Apúntate aquí</a> a nuestra newsletter!
                    </div>
                    <div className="your-pre-footer">
                        <img className="your-pre-footer-img" src="/faltas-left.png" alt="Faltas estrellas" />
                    </div>
                    <div className="your-footer">
                        <div className="your-footer-left">Sergio Oramas</div>
                        <div className="your-footer-middle">
                            <MuyaioSocial/>
                        </div>
                        <div className="your-footer-right">Developed by Bas y Andrés <br/> Design by <a href="https://opendesignstudio.eu/">OpenDesignStudio</a> </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
