import React from 'react';
import ReactSlider from 'react-slider';
import './slider.scss';

interface IProps {
    position: number;
    onChange: (position: number) => void;
}

export const Slider = ({position, onChange} : IProps) => {

    return (
        <div className="slider">
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="slider-thumb"
                trackClassName="slider-track"
                                markClassName="slider-mark"
                min={0}
                max={4}
                value={position}
                onChange={onChange}
                // renderThumb={(props, state) => <div {...props}>{getThumb(state.valueNow)}</div>}
            />
        </div>
    )
    

};
