import React  from 'react';
import './artist.scss';
import { ArtistModel } from '../../../models/ArtistModel';

interface IProps {
    artist: ArtistModel | null | undefined;
}

export const Artist = ({artist} : IProps) => {
    const artistImageUrl = artist?.image || '/artista_generico.png';
    return (
        <div className="artist">
            <div className="artist-image"><img alt="artist" src={artistImageUrl}></img></div>
            <div className="artist-name">{artist?.name}</div>
        </div>
    )
};
