import React, { useEffect, useState } from 'react';
import { AutoComplete, AutoCompleteChangeParams, AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import "primereact/resources/themes/lara-light-purple/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";
import './search.scss';
import { ArtistModel } from '../../../models/ArtistModel';
import { SearchArtists } from '../../../data/getAllArtists';

interface IProps {
    onSearch: (artist: ArtistModel | undefined) => void;
}

export const Search = ({onSearch}: IProps) => {
    const [selectedArtist, setSelectedArtist] = useState<ArtistModel | undefined>(undefined);
    const [artistsFound, setArtistsFound] = useState<ArtistModel[]>([]);
    
    useEffect(() => {
        if(selectedArtist && selectedArtist?.name && selectedArtist?.name.length > 0) {
            console.log('useEffect', selectedArtist);
            onSearch(selectedArtist);
            setSelectedArtist(undefined);
        }
    }, [selectedArtist]);
    
    const onChange = async (event: AutoCompleteChangeParams) => {
        setSelectedArtist(event.value as ArtistModel);
    };
    
    const onComplete = async (event: AutoCompleteCompleteMethodParams) => {
        const searchTerm = event.query;
        const foundArtist = await SearchArtists(searchTerm);
        setArtistsFound(foundArtist);
    };
    return (
        <div className="search">
            <AutoComplete value={selectedArtist}
                          suggestions={artistsFound}
                          field="name"
                          completeMethod={onComplete}
                          onChange={onChange}
                          placeholder="Busca tu artista favorito"
                          
            />
        </div>
    );
};
