import React from 'react';
import './About.scss';
import { MuyaioSocial } from '../buttons/MuyaioSocial/muyaioSocial';

export const About = () => {
    return (
        <div className="about section">
            <div className="section-wrapper">
                <div className="section-content">
                    <div className="about-row">
                        <div className="about-left">
                            <img src="/muyaio-left.png" alt="Estrellas"/>
                        </div>
                        <div className="about-text">
                            <div className="section-title">Muyaio</div>
                            <span>
                            Soy Muyaio, músico y algoritmólogo.
                            Te presento mi nueva canción, El Algoritmo, y con
                            ella mi nuevo algoritmo, un algoritmo justo, que
                            te hará descubrir música de calidad hecha por
                            artistas independientes, muchos de ellos
                            dificilmente recomendados en las plataformas de
                            streaming (lo sé de buena tinta, trabajo en una y
                            hasta hice un doctorado sobre ello).
                            <br/><br/>
                            Me encuentras también en:
                            </span>
                            <div className="about-share">
                                <MuyaioSocial/>
                            </div>
                        </div>
                        <div className="about-image">
                            <img src="/muyaio-foto.png" alt="Muyaio foto"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
