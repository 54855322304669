import React  from 'react';
import { faInstagram, faFacebookF, faTwitter, faSpotify,  } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons'

import './socialButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
interface IProps {
    type: 'instagram' | 'twitter' | 'facebook' | 'spotify' | 'web';
    url: string;
}

export const SocialButton = ({type, url} : IProps) => {
    const getSociaIcon = (type: 'instagram' | 'twitter' | 'facebook' | 'spotify' | 'web'): IconDefinition => {
        switch(type) {
            case "instagram":
                return faInstagram;
            case "twitter":
                return faTwitter;
            case "facebook":
                return faFacebookF;
            case "spotify":
                return faSpotify;
            case "web":
                return faGlobe;
            default:
                return faInstagram;
        }
    };
    const socialIcon = getSociaIcon(type);

    const handleClick = (): void => {
        window.open(url, '_blank');
    };
    return (
        <button className="social-button" type="button"><FontAwesomeIcon icon={socialIcon} size="xl" onClick={handleClick} title={type} /></button>
    )
};
