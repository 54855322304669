import React  from 'react';
import { Search } from '../search/search';
import { Artist } from '../artist/artist';
import './left.scss';
import { ArtistModel } from '../../../models/ArtistModel';

interface IProps {
    artist: ArtistModel | null | undefined;
    onSearch: (search: ArtistModel | undefined) => void;
}

export const Left = ({onSearch, artist} : IProps) => {
    
    return (
        <div className="left">
            <div className="search"><Search onSearch={onSearch}></Search></div>
            <div className="artist"><Artist artist={artist}></Artist></div>
        </div>
    )
};
