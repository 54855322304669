import React from 'react';
import './recommendation.scss';
import {
    FacebookIcon,
    FacebookShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from 'react-share';


interface IProps {
    playlistId: string;
}

export const Recommendation = ({playlistId} : IProps) => {
    
    const shareUrl = window.location.href;
    const shareTitle = 'Algo de Ritmo playlist';

    return (
        <div className="recommendation section">
            {!!playlistId && 
                <div className="recommendation-playlist">
                    <iframe  
                        style={{width: '100%', height: 'calc(100vh - 64px)', maxHeight: '720px'}}
                        src={`https://open.spotify.com/embed/playlist/${playlistId}?utm_source=generator&theme=0`}
                        title={'Playlist'}
                        frameBorder="0" 
                        allowFullScreen={false}
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="lazy"></iframe>
                </div>
            }
            {!playlistId &&
                <div className="recommendation-no-playlist">
                    <div className="recommendation-no-playlist-title">No se encontró una playlist</div>
                </div>
            }            
            <div className="recommendation-share" style={{ backgroundImage: "url(/comparte-playlist.png)" }}>
                <div className="recommendation-share-title">Comparte tu música!</div>
                <div className="recommendation-share-buttons">
                    <TwitterShareButton url={shareUrl} title={shareTitle} hashtags={['#algoderitmo']} >
                        <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <FacebookShareButton url={shareUrl} title={shareTitle} hashtag="#algoderitmo">
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <WhatsappShareButton url={shareUrl} title={shareTitle}>
                        <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                    <TelegramShareButton url={shareUrl} title={shareTitle}>
                        <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                    <RedditShareButton url={shareUrl} title={shareTitle}>
                        <RedditIcon size={32} round={true}></RedditIcon>
                    </RedditShareButton>
                </div>
            </div>
        </div>
    );
};
